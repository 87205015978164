enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
}

const genderTypes = [
  {
    'name': 'male',
  },

  {
    'name': 'female',
  },
];

export {
  GENDER,
  genderTypes,
};
