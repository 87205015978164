






















































import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor).extend({
  name: 'BaseAddress',

  components: {
    BaseInputField,
    BaseRow,
  },

  model: {
    event: 'update:address',
    prop: 'address',
  },

  props: {
    address: {
      type: Object,
      required: true,
    },
  },

  methods: {
    setAddressLine1(addressLine: string): void {
      this.$emit('update:address', {
        ...this.address,
        AddressLine1: addressLine,
      });
    },

    setAddressLine1OnBlur(addressLine: string): void {
      this.$emit('blur:address', {
        ...this.address,
        AddressLine1: addressLine,
      });
    },

    setAddressLine2(addressLine: string): void {
      this.$emit('update:address', {
        ...this.address,
        AddressLine2: addressLine,
      });
    },

    setAddressLine2OnBlur(addressLine: string): void {
      this.$emit('blur:address', {
        ...this.address,
        AddressLine2: addressLine,
      });
    },

    setCity(city: string): void {
      this.$emit('update:address', {
        ...this.address,
        City: city,
      });
    },

    setCityOnBlur(city: string): void {
      this.$emit('blur:address', {
        ...this.address,
        City: city,
      });
    },

    setCountry(country: string): void {
      this.$emit('update:address', {
        ...this.address,
        Country: country,
      });
    },

    setCountryOnBlur(country: string): void {
      this.$emit('blur:address', {
        ...this.address,
        Country: country,
      });
    },

    setPostalCode(postalCode: string): void {
      this.$emit('update:address', {
        ...this.address,
        Zipcode: postalCode,
      });
    },

    setPostalCodeOnBlur(postalCode: string): void {
      this.$emit('blur:address', {
        ...this.address,
        Zipcode: postalCode,
      });
    },
  },
});
