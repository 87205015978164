













import BaseButtonIcon from '@/components/base/button/BaseButtonIcon.vue';
import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor).extend({
  name: 'BaseGridButtonIcon',

  components: {
    BaseButtonIcon,
  },

  data() {
    return {};
  },

  computed: {
    action(): string {
      if (this.params.colDef.field === 'minus') {
        return 'minus';
      } else if (this.params.colDef.field === 'plus') {
        return 'plus';
      }

      return 'delete';
    },

    article(): any {
      return this.params.data.article;
    },

    isVisible(): boolean {
      if (
        this.params.data.article.flat &&
        this.params.colDef.field !== 'delete'
      ) {
        return false;
      }

      return true;
    },

    isDisabled(): boolean {
      if (this.params.colDef.field === 'minus') {
        const number = Number(this.quantity);

        return number <= 1;
      }

      return false;
    },

    quantity(): string {
      return this.params.data.minus;
    },

    type(): string {
      if (this.params.colDef.field === 'minus') {
        return 'minus';
      } else if (this.params.colDef.field === 'plus') {
        return 'add';
      }

      return 'trashcan';
    },

    variant(): string {
      if (this.params.colDef.field === 'delete') {
        return 'danger';
      }

      return 'primary';
    },
  },

  methods: {
    emitAction(): void {
      this.params.context.componentParent.onRowClick({
        action: this.action,
        Id: this.params.data.actions,
      });
    },
  },

  beforeMount() {
    // this.quantity = this.params.data.article.quantity;
  },
});
