





















































































































import BaseButtonIcon from '@/components/base/button/BaseButtonIcon.vue';
import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'NumBlock',

  components: {
    BaseButtonIcon,
    BaseButtonText,
  },

  data() {
    return {
      currentNumber: '0',
    };
  },

  methods: {
    addNumber(value): void {
      let before = this.currentNumber.slice(0, -2);
      let after = this.currentNumber.slice(-2);

      if (after.length < 1) {
        after = '00';
      } else if (after.length < 2) {
        after = '0'.concat(after);
      } else if (after.length < 3) {
        after = ''.concat(after);
      }

      if (before.length < 1) {
        before = value;
      } else {
        before = String(Number(before) + Number(value));
      }

      this.currentNumber = before.concat(after);

      const number = this.formatNumber();

      this.$emit('insert:number', Number(number).toFixed(2));
    },

    clearNumber(): void {
      this.currentNumber = '0';

      const number = this.formatNumber();

      this.$emit('insert:number', Number(number).toFixed(2));
    },

    formatNumber(): string {
      let before = this.currentNumber.slice(0, -2);
      let after = this.currentNumber.slice(-2);

      if (after.length < 1) {
        after = '.00';
      } else if (after.length < 2) {
        after = '.0'.concat(after);
      } else if (after.length < 3) {
        after = '.'.concat(after);
      }

      if (before.length < 1) {
        before = '0';
      }

      return before.concat(after);
    },

    manageKeydownValue(event): void {
      if (event?.code.includes('Digit')) {
        this.setNumber(event.key);
      }

      if (event?.code.includes('Backspace')) {
        this.removeNumber();
      }
    },

    removeNumber(): void {
      this.currentNumber = this.currentNumber.slice(0, -1);

      const number = this.formatNumber();

      this.$emit('insert:number', Number(number).toFixed(2));
    },

    setNumber(value): void {
      if (this.currentNumber === '0') {
        this.currentNumber = String(value);
      } else {
        this.currentNumber = this.currentNumber.concat(String(value));
      }

      const number = this.formatNumber();

      this.$emit('insert:number', Number(number).toFixed(2));
    },
  },

  mounted() {
    window.addEventListener('keydown', (event) => this.manageKeydownValue(event));
  },

  destroyed() {
    window.removeEventListener('keydown', (event) => this.manageKeydownValue(event));
  },
});
