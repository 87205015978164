






















import BaseTabs from '@/components/base/BaseTabs.vue';
import ProductGrid from '@/components/ProductGrid.vue';
import Vue, { VueConstructor } from 'vue';
import { MCategory } from '@/models/MCategory';
import { MProduct } from '@/models/MProduct';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'SalesGrid',

  components: {
    BaseTabs,
    ProductGrid,
  },

  data() {
    return {
      currentTabID: '',
    };
  },

  computed: {
    categories() {
      return MCategory.all();
    },

    currentCategory: {
      get(): MCategory {
        return MCategory.find(this.currentTabID);
      },

      set(ID): void {
        this.currentTabID = ID;
      },
    },

    productList() {
      return MProduct
        .query()
        .where('CategoryId', this.currentTabID)
        .where('Incomplete', false)
        .get();
    },

    tabRoutes() {
      const tabRoutes = [];

      this.categories.forEach(category => {
        tabRoutes.push(
          {
            Id: category.Id,
            heading: category.CategoryName,
          },
        );
      });

      return tabRoutes;
    },
  },

  methods: {
    changeTab(tabId): void {
      this.currentCategory = tabId;
    },
  },

  mounted() {
    this.currentCategory = this.categories[0]?.Id;
  },
});
