









































import BaseTagWrapper from '@/components/base/BaseTagWrapper.vue';

export default {
  name: 'BaseCard',

  components: {
    BaseTagWrapper,
  },

  computed: {
    showDescription(): boolean {
      return this.$slots.title || this.$slots.subtitle || this.$slots.kicker;
    },
  },
};
