


























import BaseGridList from '@/components/base/grid/BaseGridList.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import Vue, { VueConstructor } from 'vue';
import { cloneDeep as _cloneDeep } from 'lodash';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { MSales } from '@/models/MSales';
import { MShoppingBag } from '@/models/MShoppingBag';
import { shoppingBagCallupGridActionList } from '@/utils/gridActions/shoppingBagGridActionList';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'ShoppingBagCallUp',

  components: {
    BaseGridList,
    BaseModal,
    LayoutHeader,
  },

  mixins: [
    mixinAlert,
    mixinModal,
  ],

  data() {
    return {
      gridActionList: shoppingBagCallupGridActionList,
      gridColumnDefinition: [
        {
          headerName: String(this.$tc('shoppingBag.model', 1)),
          field: 'name',
        },
      ],
    };
  },

  computed: {
    currentShoppingBag(): MShoppingBag {
      return MShoppingBag
        .query()
        .where('Current', true)
        .first();
    },

    shoppingBags(): Array<Object> {
      return MShoppingBag
        .query()
        .withAllRecursive()
        .where('InvoiceID', '')
        .get().map(shoppingBag => {
          let price = 0.00;

          shoppingBag.Sales.forEach(sale => {
            price += _cloneDeep(Number(sale.asListData.price));
          });

          return {
            actions: shoppingBag.Id,
            name:
              `${String(this.$tc('shoppingBag.model', 1))}
              ${this.$tc('product.model', 1)}:
              ${shoppingBag.Sales.length},
              ${this.$tc('sales.property.price', 1)}:
              ${MSales.formatedPrice(price)}`,
          };
        });
    },
  },

  methods: {
    clickedRow(Id: string): void {
      this.$router.push({
        ...this.routeBack,

        query: {
          shoppingBagId: Id,
        },
      });
    },

    closeModal(): void {
      this.$router.push(this.routeBack);
    },

    async remove(shoppingBagID: string): Promise<any> {
      const confirmation = await this.confirmAlertDelete(
        this.$tc('shoppingBag.alert.delete'),
      );

      if (confirmation.value) {
        MShoppingBag._delete(shoppingBagID);
      }
    },
  },
});
