






































































































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import SalesGrid from '@/views/sales/partials/SalesGrid.vue';
import ShoppingBag from '@/views/sales/partials/ShoppingBag.vue';
import Vue, { VueConstructor } from 'vue';
import { cloneDeep as _cloneDeep, isNil as _isNil } from 'lodash';
import {
  customerRoute,
  paymentRoute,
  shoppingBagCallUpRouteEdit,
  shoppingBagRouteShow,
  shoppingBagSalesRouteEdit,
  shoppingBagSalesRouteNew,
} from '@/views/shoppingBag/routes';
import { EventBus, EventBusEvent } from '@/utils/eventBus';
import { MCustomer } from '@/models/MCustomer';
import { MInvoice } from '@/models/MInvoice';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { mixinPDF } from '@/components/mixins/pdf';
import { MSales } from '@/models/MSales';
import { MShoppingBag } from '@/models/MShoppingBag';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'ShoppingBagShow',

  components: {
    BaseButtonText,
    LayoutHeader,
    LayoutPage,
    SalesGrid,
    ShoppingBag,
  },

  mixins: [
    mixinAlert,
    mixinPDF,
    mixinModal,
  ],

  props: {
    shoppingBagId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      currentShoppingBagId: _cloneDeep(this.shoppingBagId),
      invoiceIsLoading: false,
    };
  },

  computed: {
    callUpText(): string {
      return `${this.$t('sales.property.callUp')} (${this.shoppingBags.length})`;
    },

    currentCustomer(): string {
      if (this.currentShoppingBag?.CustomerID) {
        return MCustomer.find(this.currentShoppingBag.CustomerID).name;
      }

      return this.$tc('customer.model', 1);
    },

    currentShoppingBag: {
      get(): MShoppingBag {
        return MShoppingBag.query().withAllRecursive().find(this.currentShoppingBagId);
      },

      set(Id: string): void {
        MShoppingBag.update({
          where: this.currentShoppingBagId,
          data: {
            Current: false,
          },
        });

        if (!_isNil(Id)) {
          MShoppingBag.update({
            where: Id,
            data: {
              Current: true,
            },
          });
        }
      },
    },

    hasShoppingBag(): Boolean {
      return utIsEmpty(this.currentShoppingBagId);
    },

    hasShoppingBags(): Boolean {
      return this.shoppingBags.length > 0;
    },

    shoppingBags(): Array<MShoppingBag> {
      return MShoppingBag.query().where('InvoiceID', '').get();
    },
  },

  watch: {
    shoppingBagId() {
      this.currentShoppingBagId = _cloneDeep(this.shoppingBagId);
    },
  },

  methods: {
    async addProduct(productId: string): Promise<any> {
      let shoppingBagId = this.currentShoppingBagId;

      if (this.hasShoppingBag) {
        shoppingBagId = await MShoppingBag._create();
      }

      await this.$router.push({
        name: shoppingBagSalesRouteNew(this.$i18n.locale).name,

        params: {
          productId: productId,
          shoppingBagId: shoppingBagId,
        },
      });
    },

    chooseCustomer(): void {
      this.$router.push({
        name: customerRoute(this.$i18n.locale).name,

        query: {
          shoppingBagId: this.currentShoppingBag.Id,
        },
      });
    },

    async clearShoppingBag(): Promise<any> {
      for (let index = 0; index < this.currentShoppingBag.Sales.length; index++) {
        const sale = this.currentShoppingBag.Sales[index];

        await MSales._delete(sale.Id);
      }

      await MShoppingBag._delete(this.currentShoppingBag.Id);

      this.currentShoppingBagId = '';
    },

    closeModal(): void {
      this.$router.push(shoppingBagRouteShow(this.$i18n.locale));
    },

    async editSale(Id: string): Promise<any> {
      await this.$router.push({
        name: shoppingBagSalesRouteEdit(this.$i18n.locale).name,

        params: {
          productId: Id,
        },

        query: {
          shoppingBagId: this.currentShoppingBagId,
        },
      });
    },

    async minusQuantity(Id: string): Promise<any> {
      const sale = MSales.find(Id);

      const quantity = Number(sale.Quantity) - 1;
      const price = quantity * Number(sale.SinglePrice);
      const discountPrice = (price / 100 * (100 - Number(sale.Discount))).toFixed(2);

      await MSales.update({
        where: Id,

        data: {
          ...sale,
          Quantity: quantity,
          Price: discountPrice,
        },
      });

      await MSales._update(MSales.find(Id));
    },

    openShoppingBag(): void {
      this.$router.push({
        name: shoppingBagCallUpRouteEdit(this.$i18n.locale).name,

        query: {
          shoppingBagId: this.currentShoppingBagId,
        },
      });
    },

    parkShoppingBag(): void {
      this.currentShoppingBagId = '';
    },

    openPayment(): void {
      this.$router.push({
        name: paymentRoute(this.$i18n.locale).name,

        query: {
          shoppingBagId: this.currentShoppingBag.Id,
        },
      });
    },

    async pay(type: string, paymentMethod: string, additionalText: string): Promise<any> {
      EventBus.$emit(EventBusEvent.CALL.update);

      const invoice = await MInvoice._create(
        this.currentShoppingBag,
        additionalText,
        paymentMethod,
      );

      MShoppingBag.update({
        Id: this.shoppingBagId,
        ...this.currentShoppingBag,
        InvoiceID: invoice.Id,
      });

      await MShoppingBag._update(this.currentShoppingBag);

      this.currentShoppingBagId = '';

      this.showModal = false;

      EventBus.$emit(EventBusEvent.CALL.updateEnd);

      if (type === 'bon') {
        this.$refs['hiddenPdf'].src = await this.generateBon(invoice);
      } else {
        this.$refs['hiddenPdf'].src = await this.generatePdf(invoice);
      }
    },

    async plusQuantity(Id: string): Promise<any> {
      const sale = MSales.find(Id);

      const quantity = Number(sale.Quantity) + 1;
      const price = quantity * Number(sale.SinglePrice);
      const discountPrice = (price / 100 * (100 - Number(sale.Discount))).toFixed(2);

      await MSales.update({
        where: Id,

        data: {
          ...sale,
          Quantity: quantity,
          Price: discountPrice,
        },
      });

      await MSales._update(MSales.find(Id));
    },

    async removeSale(Id: string): Promise<any> {
      await MSales._delete(Id);
    },

    async quickPrint(): Promise<any> {
      EventBus.$emit(EventBusEvent.CALL.update);

      const invoice = await MInvoice._create(this.currentShoppingBag);

      MShoppingBag.update({
        Id: this.shoppingBagId,
        ...this.currentShoppingBag,
        InvoiceID: invoice.Id,
      });

      await MShoppingBag._update(this.currentShoppingBag);

      this.currentShoppingBagId = '';

      EventBus.$emit(EventBusEvent.CALL.updateEnd);

      this.showModal = false;

      this.$refs['hiddenPdf'].src = await this.generateBon(invoice);
    },
  },

  mounted(): void {
  },
});
