


























































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import BaseSideLabel from '@/components/base/BaseSideLabel.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutModalEdit from '@/layouts/LayoutModalEdit.vue';
import Vue, { VueConstructor } from 'vue';
import { i18n } from '@/locales/i18n';
import { MCustomer } from '@/models/MCustomer';
import { MInvoice } from '@/models/MInvoice';
import { mixinModal } from '@/components/mixins/modal';
import { mixinModes } from '@/components/mixins/modes';
import { mixinPDF } from '@/components/mixins/pdf';
import { MMail } from '@/models/MMail';
import { MShoppingBag } from '@/models/MShoppingBag';
import { SELECTIONSTATE } from '@/utils/states';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor).extend({
  name: 'SendMail',

  components: {
    BaseButtonText,
    BaseCheckbox,
    BaseInputField,
    BaseRow,
    BaseSideLabel,
    LayoutModalEdit,
    LayoutHeader,
  },

  mixins: [
    mixinModal,
    mixinModes,
    mixinPDF,
  ],

  props: {
    shoppingBagId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      recipient: '',
      recipientMail: '',
      differentRecipient: SELECTIONSTATE.UNCHECKED,
      SELECTIONSTATE,
    };
  },

  computed: {
    customer(): MCustomer {
      if (this.shoppingBag && this.shoppingBag?.CustomerID) {
        return MCustomer.find(this.shoppingBag.CustoemrID);
      }

      return null;
    },

    shoppingBag(): MShoppingBag {
      return MShoppingBag
        .query()
        .withAllRecursive()
        .find(this.shoppingBagId);
    },

    isDisabled(): boolean {
      if (this.shoppingBag && utIsEmpty(this.shoppingBag.CustomerID)) {
        return true;
      }

      return false;
    },
  },

  methods: {
    closeModal(): void {
      this.$router.push(this.routeBack);
    },

    async sendInvoice(): Promise<void> {
      this.loading = true;

      const invoice = MInvoice.query().withAllRecursive().find(this.shoppingBag.InvoiceID);

      let recipient;

      let pdf = await this.generatePdf(invoice, '', false, true);

      pdf = pdf.replace('data:application/pdf;base64,', '');

      const invoiceNumber = invoice.InvoiceNumber;

      const fileName = `${this.$tc('invoice.model', 1)}-${invoice.InvoiceNumber}`;

      if (this.customer && !utIsEmpty(this.customer.Email)) {
        recipient = {
          address: this.customer.Email,
          name: this.customer.name,
        };
      }

      if (this.differentRecipient === SELECTIONSTATE.CHECKED) {
        recipient = {
          address: this.recipientMail,
          name: this.recipient,
        };
      }

      const mail = {
        attachment: pdf,
        customer: recipient,
        fileName: `${fileName}.pdf`,
        message: this.$tc('mailing.message.invoice', {
          customer: recipient?.name,
          invoiceNumber: invoiceNumber,
        }),
        subject: fileName,
      };

      await MMail._sendMail(mail);

      this.loading = false;

      this.closeModal();
    },
  },

  beforeMount(): void {
    if (this.shoppingBag && utIsEmpty(this.shoppingBag.CustomerID)) {
      this.differentRecipient = SELECTIONSTATE.CHECKED;
    }
  },
});
