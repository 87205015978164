

































import BaseCard from '@/components/base/BaseCard.vue';
import BaseImage from '@/components/base/BaseImage.vue';
import BaseTag from '@/components/base/BaseTag.vue';
import ProductImageWithMask from '@/components/ProductImageWithMask.vue';
import Vue, { VueConstructor } from 'vue';
import { MProduct } from '@/models/MProduct';

export default (Vue as VueConstructor).extend({
  name: 'ProductCard',

  components: {
    BaseCard,
    BaseImage,
    BaseTag,
    ProductImageWithMask,
  },

  props: {
    labelProperty: {
      type: String,
      default: '',
    },

    productID: {
      type: String,
      required: true,
    },
  },

  computed: {
    isPlaceholder(): Boolean {
      return this.product?.showPlaceholder === true;
    },

    product(): MProduct {
      return MProduct.find(this.productID);
    },

    productAltText(): string {
      return this.product?.ImageAltText || '';
    },

    productImageURL(): string {
      return this.product?.productImageURL();
    },

    productTag(): string {
      if (this.labelProperty === 'Price') {
        if (this.productAltText !== '') {
          return `${this.product?.Name} ${this.product?.formatedPrice}` || '';
        }

        return `${this.product?.formatedPrice}` || '';
      }

      return this.product[this.labelProperty];
    },
  },
});
