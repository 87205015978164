










































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import Vue, { VueConstructor } from 'vue';
import { find as _find, findIndex as _findIndex } from 'lodash';
import { mixinComponentUID } from '@/components/mixins/componentUID';
import { RouteConfig } from 'vue-router';

enum TAB_NAVIGATION_DIRECTION {
  BACK = 0,
  FORWARD = 1,
}

type TTabNavigationDirection =
  TAB_NAVIGATION_DIRECTION.BACK |
  TAB_NAVIGATION_DIRECTION.FORWARD;

export default (Vue as VueConstructor<Vue>).extend({
  name: 'BaseTabs',

  components: {
    BaseButtonText,
  },

  mixins: [
    mixinComponentUID,
  ],

  props: {
    activeTab: {
      type: String,
      default: '',
    },

    isRouteConfig: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      required: true,
    },

    tabList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      focusedTabItemIndex: 0,
      navigationDirection: {
        back: TAB_NAVIGATION_DIRECTION.BACK,
        forward: TAB_NAVIGATION_DIRECTION.FORWARD,
      },
    };
  },

  computed: {
    selectedTabIndex(): number {
      let tabIndex = _findIndex(
        this.tabList,
        [
          'heading',
          this.selectedTabName,
        ],
      );

      if (tabIndex < 0) {
        tabIndex = 0;
      }

      return tabIndex;
    },

    selectedTabName(): string {
      if (!this.isRouteConfig) {
        return _find(
          this.tabList,
          [
            'Id',
            this.activeTab,
          ],
        )?.heading;
      }

      return this.$route.meta.label;
    },
  },

  methods: {
    isTabNameMatchingSelectedTabName(tabName: string): boolean {
      return this.selectedTabName === tabName;
    },

    onTabListNavigation(direction: TTabNavigationDirection): void {
      const tabItems = document.querySelectorAll(`#tabs--${this.componentUID} .tabs__item`);

      if (direction === this.navigationDirection.back) {
        this.focusedTabItemIndex--;

        if (this.focusedTabItemIndex < 0) {
          this.focusedTabItemIndex = this.tabList.length - 1;
        }
      }

      if (direction === this.navigationDirection.forward) {
        this.focusedTabItemIndex++;

        if (this.focusedTabItemIndex >= this.tabList.length) {
          this.focusedTabItemIndex = 0;
        }
      }

      const newActiveTabItem = tabItems[this.focusedTabItemIndex] as HTMLElement;

      newActiveTabItem.focus();
    },

    showTab(tab): void {
      if (this.isRouteConfig) {
        if (this.selectedTabName !== tab.name) {
          this.$router.push({
            name: tab.name,
          });
        }
      } else {
        this.$emit('change:tab', tab.Id);
      }
    },
  },

  created(): void {
    this.focusedTabItemIndex = this.selectedTabIndex;
  },
});
