export interface IAddress {
  Id?: string,
  AddressLine1: string,
  AddressLine2: string,
  City: string,
  Country: string,
  Zipcode: string,
}

const initAddress = (address: IAddress = null): IAddress => {
  if (address) {
    return {
      Id: address.Id,
      AddressLine1: address.AddressLine1,
      AddressLine2: address.AddressLine2,
      City: address.City,
      Country: address.Country,
      Zipcode: address.Zipcode,
    };
  } else {
    return {
      Id: null,
      AddressLine1: '',
      AddressLine2: '',
      City: '',
      Country: '',
      Zipcode: '',
    };
  }
};

export { initAddress };
