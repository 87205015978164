


















































































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import SalesGrid from '@/views/sales/partials/SalesGrid.vue';
import ShoppingBag from '@/views/sales/partials/ShoppingBag.vue';
import Vue, { VueConstructor } from 'vue';
import {
  customerRoute,
  deliveryBagRouteShow,
  deliveryBagSalesRouteEdit,
  deliveryBagSalesRouteNew,
} from '@/views/deliveryBag/routes';
import { EventBus, EventBusEvent } from '@/utils/eventBus';
import { MCustomer } from '@/models/MCustomer';
import { MDeliveryBag } from '@/models/MDeliveryBag';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { mixinPDF } from '@/components/mixins/pdf';
import { MSales } from '@/models/MSales';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'DeliveryBagShow',

  components: {
    BaseButtonText,
    LayoutHeader,
    LayoutPage,
    SalesGrid,
    ShoppingBag,
  },

  mixins: [
    mixinAlert,
    mixinPDF,
    mixinModal,
  ],

  computed: {
    currentCustomer(): string {
      if (this.hasCustomer) {
        return MCustomer.find(this.currentDeliveryBag.CustomerID).name;
      }

      return this.$tc('customer.model', 1);
    },

    currentDeliveryBag(): any {
      return MDeliveryBag.query().withAllRecursive().where('Current', true).first();
    },

    currentDeliveryBagId(): string {
      if (this.currentDeliveryBag !== null) {
        return this.currentDeliveryBag?.Id;
      }

      return '';
    },

    hasCustomer(): boolean {
      if (this.hasDeliveryBag) {
        return !utIsEmpty(this.currentDeliveryBag.CustomerID);
      }

      return false;
    },

    hasDeliveryBag(): Boolean {
      return !utIsEmpty(this.currentDeliveryBagId);
    },
  },

  methods: {
    async addProduct(productId: string): Promise<any> {
      let deliveryBagId = this.currentDeliveryBag?.Id;

      if (!this.hasDeliveryBag) {
        deliveryBagId = await MDeliveryBag._create();
      }

      await this.$router.push({
        name: deliveryBagSalesRouteNew(this.$i18n.locale).name,

        params: {
          productId: productId,
          deliveryBagId: deliveryBagId,
        },
      });
    },

    chooseCustomer(): void {
      this.$router.push({
        name: customerRoute(this.$i18n.locale).name,

        query: {
          deliveryBagId: this.currentDeliveryBag.Id,
        },
      });
    },

    async clearDeliveryBag(): Promise<any> {
      for (let index = 0; index < this.currentDeliveryBag.Sales.length; index++) {
        const sale = this.currentDeliveryBag.Sales[index];

        await MSales._delete(sale.Id);
      }

      await MDeliveryBag._delete(this.currentDeliveryBag.Id);
    },

    closeModal(): void {
      this.$router.push(deliveryBagRouteShow(this.$i18n.locale));
    },

    async editSale(Id: string): Promise<any> {
      await this.$router.push({
        name: deliveryBagSalesRouteEdit(this.$i18n.locale).name,

        params: {
          productId: Id,
        },

        query: {
          deliveryBagId: this.currentDeliveryBag?.Id,
        },
      });
    },

    async minusQuantity(Id: string): Promise<any> {
      const sale = MSales.find(Id);

      const quantity = Number(sale.Quantity) - 1;
      const price = quantity * Number(sale.SinglePrice);
      const discountPrice = (price / 100 * (100 - Number(sale.Discount))).toFixed(2);

      await MSales.update({
        where: Id,

        data: {
          ...sale,
          Quantity: quantity,
          Price: discountPrice,
        },
      });

      await MSales._update(MSales.find(Id));
    },

    async newDelivery(): Promise<any> {
      EventBus.$emit(EventBusEvent.CALL.update);

      MDeliveryBag._create();

      EventBus.$emit(EventBusEvent.CALL.updateEnd);
    },

    async plusQuantity(Id: string): Promise<any> {
      const sale = MSales.find(Id);

      const quantity = Number(sale.Quantity) + 1;
      const price = quantity * Number(sale.SinglePrice);
      const discountPrice = (price / 100 * (100 - Number(sale.Discount))).toFixed(2);

      await MSales.update({
        where: Id,

        data: {
          ...sale,
          Quantity: quantity,
          Price: discountPrice,
        },
      });

      await MSales._update(MSales.find(Id));
    },

    async removeSale(Id: string): Promise<any> {
      await MSales._delete(Id);
    },

    async quickPrint(): Promise<any> {
      EventBus.$emit(EventBusEvent.CALL.update);

      const deliveryBagId = this.currentDeliveryBag.Id;

      await MDeliveryBag.update({
        where: deliveryBagId,
        data: {
          Current: false,
        },
      });

      const currentDeliveryBag = MDeliveryBag.query().withAllRecursive().find(deliveryBagId);

      await MDeliveryBag._update(currentDeliveryBag);

      EventBus.$emit(EventBusEvent.CALL.updateEnd);

      this.$refs['hiddenPdf'].src = await this.generateDelivery(currentDeliveryBag);
    },
  },
});
