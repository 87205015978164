import { ACTION, actionMap } from './actionMap';
import { IBaseGridActionListItem } from '@/components/base/grid/typings/interface/IBaseGridActionListItem';

const shoppingBagGridActionList: Array<IBaseGridActionListItem> = [
  {
    ...actionMap.get(ACTION.CONTEXT_MENU),
    childActionList: [
      actionMap.get(ACTION.ADD),
      actionMap.get(ACTION.EDIT),
    ],
  },
];

const shoppingBagCallupGridActionList: Array<IBaseGridActionListItem> = [
  {
    ...actionMap.get(ACTION.DELETE),
  },
];

export { shoppingBagGridActionList, shoppingBagCallupGridActionList };
