







export default {
  name: 'BaseTagWrapper',
};
