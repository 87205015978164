



































































import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseSideLabel from '@/components/base/BaseSideLabel.vue';
import BaseToggle from '@/components/base/BaseToggle.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutModalEdit from '@/layouts/LayoutModalEdit.vue';
import Vue, { VueConstructor } from 'vue';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { mixinModes } from '@/components/mixins/modes';
import { mixinSync } from '@/components/mixins/sync';
import { MProduct } from '@/models/MProduct';
import { MSales } from '@/models/MSales';

export default (Vue as VueConstructor).extend({
  name: 'SalesEdit',

  components: {
    BaseInputField,
    BaseSideLabel,
    BaseToggle,
    LayoutModalEdit,
    LayoutHeader,
  },

  mixins: [
    mixinAlert,
    mixinModal,
    mixinModes,
    mixinSync,
  ],

  props: {
    ID: {
      type: String,
      required: true,
    },
  },

  computed: {
    currentSale: {
      get(): MSales {
        return MSales.find(this.ID);
      },

      async set(sale: MSales) {
        this.dirty = true;

        await MSales.update({
          where: this.currentSale.ID,

          data: sale,
        });
      },
    },

    currentProduct(): MProduct {
      return MProduct.find(this.currentSale?.ProductID);
    },

    currentQuantity(): string {
      return String(this.currentSale?.Quantity);
    },
  },

  methods: {
    closeModal(): void {
      this.$router.push({
        ...this.routeBack,

        query: {
          shoppingBagId: this.currentSale.ShoppingBagID,
        },
      });
    },

    updateDiscount(value): void {
      const price = this.currentSale.Quantity * this.currentSale.SinglePrice;
      const discountPrice = (price / 100 * (100 - value)).toFixed(2);

      this.currentSale = {
        ...this.currentSale,
        Price: discountPrice,
        Discount: value,
      };
    },

    updateSale(property, value): void {
      this.currentSale = {
        ...this.currentSale,
        [property]: value,
      };
    },

    updateQuantity(value): void {
      const price = (value * this.currentSale.SinglePrice).toFixed(2);

      this.currentSale = {
        ...this.currentSale,
        Price: price,
        Quantity: value,
      };
    },

    async remove(): Promise<any> {
      const confirmation = await this.confirmAlertDelete(
        this.$tc('sale.alert.delete', {
          name: '',
        }),
      );

      if (confirmation.value) {
        await MSales._delete(this.ID);

        this.closeModal();
      }
    },

    setFlat(value: boolean): void {
      this.currentSale = {
        ...this.currentSale,
        Flat: value,
        Quantity: 1,
        Price: this.currentSale.SinglePrice,
      };
    },

    sync(): void {
      MSales._update(this.currentSale);
    },
  },
});
