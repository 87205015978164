import { render, staticRenderFns } from "./BaseTag.vue?vue&type=template&id=352f8e6c&scoped=true&"
import script from "./BaseTag.vue?vue&type=script&lang=ts&"
export * from "./BaseTag.vue?vue&type=script&lang=ts&"
import style0 from "./BaseTag.vue?vue&type=style&index=0&id=352f8e6c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "352f8e6c",
  null
  
)

export default component.exports