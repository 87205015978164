












import ProductCard from '@/components/ProductCard.vue';
import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'ProductGrid',

  components: {
    ProductCard,
  },

  props: {
    labelProperty: {
      type: String,
      default: 'Price',
    },

    productList: {
      type: Array,
      required: true,
    },
  },

  computed: {
    list(): Boolean {
      return this.productList;
    },
  },
});
