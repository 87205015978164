




































































































































































import BaseAddress from '@/components/base/BaseAddress.vue';
import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseSideLabel from '@/components/base/BaseSideLabel.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutModalEdit from '@/layouts/LayoutModalEdit.vue';
import uuid4 from 'uuid/v4';
import Vue, { VueConstructor } from 'vue';
import { CONTEXT_NAME } from '@/utils/contextName';
import { genderTypes } from '@/utils/genderTypes';
import { IAddress, initAddress } from '@/typings/interface/IAddress';
import { MAddress } from '@/models/MAddress';
import { MCustomer } from '@/models/MCustomer';
import { MDeliveryBag } from '@/models/MDeliveryBag';
import { mixinModal } from '@/components/mixins/modal';
import { mixinModes } from '@/components/mixins/modes';
import { mixinSync } from '@/components/mixins/sync';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'CustomerAdd',

  components: {
    BaseAddress,
    BaseButtonText,
    BaseInputField,
    BaseRow,
    BaseSideLabel,
    BaseSelect,
    LayoutHeader,
    LayoutModalEdit,
  },

  mixins: [
    mixinModal,
    mixinModes,
    mixinSync,
  ],

  props: {
    deliveryBagId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      addressKey: 0,
      newCustomerId: '',
      CONTEXT_NAME,
      genderTypes,
      useCustomerPrices: false,
      selectedCustomerId: '',
    };
  },

  computed: {
    currentCustomer: {
      get(): MCustomer {
        return MCustomer.query().withAllRecursive().find(this.newCustomerId);
      },

      async set(customer: MCustomer) {
        this.dirty = true;

        await MCustomer.update({
          where: this.ID,

          data: customer,
        });
      },
    },

    customerList(): Array<Object> {
      return MCustomer
        .all()
        .map(customer => {
          return {
            Id: customer.Id,
            name: customer.name,
          };
        });
    },

    currentCustomerAddress: {
      get(): IAddress {
        if (this.currentCustomer.Address !== null) {
          return this.currentCustomer.Address;
        }

        return initAddress();
      },

      set(address: IAddress) {
        if (!utIsEmpty(address)) {
          this.dirty = true;

          if (address.Id !== null) {
            MAddress.update({
              data: address,
            });
          } else {
            MAddress.create({
              data: {
                ...address,
                Id: uuid4(),
                OwnerID: this.currentCustomer.Id,
              },
            });
          }
        }
      },
    },

    currentCustomerGender: {
      get() {
        return {
          name: this.currentCustomer.Sex,
        };
      },

      set(value): void {
        this.currentCustomer = {
          ...this.currentCustomer,
          Sex: value,
        };
      },
    },

    selectedCustomer(): Object {
      if (utIsEmpty(this.selectedCustomerId)) {
        return {};
      }

      const currentCustomer = MCustomer.find(this.selectedCustomerId);

      return {
        Id: this.selectedCustomerId,
        name: currentCustomer.name,
      };
    },
  },

  methods: {
    async createCustomer(): Promise<any> {
      this.newCustomerId = await MCustomer._create();
    },

    closeModal(): void {
      MDeliveryBag.updateCustomer(
        this.deliveryBagId,
        this.selectedCustomerId,
      );

      this.$router.push({
        name: this.routeBack.name,

        query: {
          deliveryBagId: this.deliveryBagId,
        },
      });
    },

    setSelectedCustomer(value): void {
      if (value === null) {
        this.selectedCustomerId = '';
      } else {
        this.selectedCustomerId = value.Id;
      }
    },

    sync(): void {
      MCustomer._update(this.currentCustomer);
    },

    updateCustomer(prop, value): void {
      if (this.currentCustomer[prop] !== value) {
        this.currentCustomer = {
          ...this.currentCustomer,
          [`${prop}`]: value,
        };
      }
    },
  },

  mounted(): void {
    this.selectedCustomerId = MDeliveryBag.find(this.deliveryBagId).CustomerID;
  },
});
