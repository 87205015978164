




































import Vue, { VueConstructor } from 'vue';
import { MSales } from '@/models/MSales';

export default (Vue as VueConstructor).extend({
  name: 'BaseGridSalesRow',

  computed: {
    article() {
      return this.params.data.article;
    },
  },

  methods: {
    formatPrice(price): string {
      return MSales.formatedPrice(price);
    },
  },
});
