










export default {
  name: 'BaseTag',

  props: {
    variantList: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  computed: {
    tagVariant() {
      return this.variantList.map(
        (variant) => {
          return `tag--${variant}`;
        },
      );
    },
  },
};
