

























import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'BaseToggle',

  model: {
    event: 'changed:toggle',
    prop: 'value',
  },

  props: {
    /**
     * disable toggle
     */
    disabled: {
      default: false,
      type: Boolean,
    },

    /**
     * given value
     */
    value: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      focused: false,
    };
  },

  computed: {
    className() {
      return {
        'toggle--enabled': this.toggled,
        'toggle--disabled': this.disabled,
        'toggle--focused': this.focused,
      };
    },

    toggled: {
      get(): string {
        return this.value;
      },

      set(toggled: boolean) {
        this.$emit('changed:toggle', toggled);

        this.$emit('focus', false);
      },
    },
  },
});
