



































































































































































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutModalEdit from '@/layouts/LayoutModalEdit.vue';
import NumBlock from '@/views/sales/partials/NumBlock.vue';
import Vue, { VueConstructor } from 'vue';
import { cloneDeep as _cloneDeep } from 'lodash';
import { MCustomer } from '@/models/MCustomer';
import { MInvoice } from '@/models/MInvoice';
import { mixinModal } from '@/components/mixins/modal';
import { mixinModes } from '@/components/mixins/modes';
import { mixinSync } from '@/components/mixins/sync';
import { MSales } from '@/models/MSales';
import { MShoppingBag } from '@/models/MShoppingBag';
import { paymentMethodList, paymentMethodMap } from '@/utils/paymentMethod';
import { shoppingBagSendMail } from '@/views/shoppingBag/routes';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor).extend({
  name: 'Payment',

  components: {
    BaseButtonText,
    BaseInputField,
    BaseRow,
    LayoutModalEdit,
    LayoutHeader,
    NumBlock,
  },

  mixins: [
    mixinModal,
    mixinModes,
    mixinSync,
  ],

  props: {
    shoppingBagId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      addtionalText: '',
      customerId: '',
      givenPrice: 0.00,
      paymentMethod: '',
      paymentMethodList,
    };
  },

  computed: {
    absolutePrice(): number {
      let price = 0.00;

      this.currentShoppingBag.Sales.forEach(sale => {
        price += _cloneDeep(Number(sale.asListData.price));
      });

      return price;
    },

    currentCustomer(): string {
      if (this.currentShoppingBag) {
        return MCustomer.find(this.currentShoppingBag.CustomerID)?.name;
      }

      return '';
    },

    currentPaymentMethod: {
      get(): Object {
        if (this.paymentMethod) {
          return paymentMethodMap.get(this.paymentMethod);
        }

        return {};
      },

      set(value): void {
        this.paymentMethod = value;
      },
    },

    currentShoppingBag(): MShoppingBag {
      return MShoppingBag.query().withAllRecursive().find(this.shoppingBagId);
    },

    isDisabled(): boolean {
      return utIsEmpty(this.paymentMethod);
    },

    returnPrice(): number {
      if (this.givenPrice < this.absolutePrice) {
        return 0.00;
      }

      return this.givenPrice - this.absolutePrice;
    },
  },

  methods: {
    addGivenNumber(value): void {
      this.givenPrice = value;
    },

    closeModal(): void {
      this.$router.push({
        name: this.routeBack.name,

        query: {
          shoppingBagId: this.shoppingBagId,
        },
      });
    },

    formatedPrice(value): string {
      return MSales.formatedPrice(value);
    },

    updateGivenPrice(value): void {
      this.givenPrice = value;
    },

    updatePaymentMethod(type): void {
      this.paymentMethod = type;
    },

    async pay(type): Promise<any> {
      this.$emit('pay', type, this.paymentMethod, this.additionalText);
    },

    async showMailForm(): Promise<void> {
      const invoice = await MInvoice._create(
        this.currentShoppingBag,
        this.additionalText,
        this.paymentMethod,
      );

      await MShoppingBag.update({
        Id: this.shoppingBagId,
        ...this.currentShoppingBag,
        InvoiceID: invoice.Id,
      });

      await MShoppingBag._update(this.currentShoppingBag);

      this.$router.push({
        name: shoppingBagSendMail(this.$i18n.locale).name,

        params: {
          shoppingBagId: this.shoppingBagId,
        },
      });
    },
  },
});
