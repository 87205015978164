















































import BaseGridButtonIcon from '@/components/base/grid/BaseGridButtonIcon.vue';
import BaseGridSalesRow from '@/components/base/grid/BaseGridSalesRow.vue';
import BaseGridTable from '@/components/base/grid/BaseGridTable.vue';
import BaseHeading from '@/components/base/BaseHeading.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import Vue, { VueConstructor } from 'vue';
import { cloneDeep as _cloneDeep } from 'lodash';
import { MCustomer } from '@/models/MCustomer';
import { MDeliveryBag } from '@/models/MDeliveryBag';
import { MSales } from '@/models/MSales';
import { MShoppingBag } from '@/models/MShoppingBag';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'ShoppingBag',

  components: {
    BaseGridTable,
    BaseHeading,
    BaseRow,
  },

  props: {
    bagID: {
      type: String,
      default: '',
    },

    buttons: {
      type: Number,
      default: 3,
    },

    type: {
      type: String,
      default: 'shoppingBag',
    },
  },

  data() {
    return {
      gridActionList: [],

      gridColumnDefinition: [
        {
          headerName: '',
          field: 'minus',
          cellRendererFramework: BaseGridButtonIcon,
          sort: 'none',
        },
        {
          headerName: this.$t('shoppingBag.property.article'),
          field: 'article',
          cellRendererFramework: BaseGridSalesRow,
        },
        {
          headerName: '',
          field: 'plus',
          cellRendererFramework: BaseGridButtonIcon,
        },
        {
          headerName: this.$t('shoppingBag.property.total'),
          field: 'total',
        },
        {
          headerName: '',
          field: 'delete',
          cellRendererFramework: BaseGridButtonIcon,
        },
      ],

      totalPrice: '',
    };
  },

  computed: {
    articleList() {
      if (utIsEmpty(this.bagID)) {
        return [];
      }

      return MSales
        .query()
        .where('ShoppingBagID', this.bagID)
        .get()
        .map(sale => sale?.asListData);
    },

    classList(): Array<string> {
      return [
        `bag-button-grid--${this.buttons}`,
      ];
    },

    gridRowData(): Array<any> {
      let total = 0.00;
      let customer, bag;

      if (this.type === 'shoppingBag') {
        bag = MShoppingBag.find(this.bagID);
      } else {
        bag = MDeliveryBag.find(this.bagID);
      }

      if (!utIsEmpty(bag?.CustomerID)) {
        customer = MCustomer.find(bag.CustomerID);
      }

      const rowData = this.articleList.map(article => {
        const clonedArticle = _cloneDeep(article);

        total += _cloneDeep(Number(article.price));

        if (
          article.tax === '10' &&
          (!utIsEmpty(customer?.CompanyName) || !utIsEmpty(customer?.Uid))
        ) {
          clonedArticle.tax = '13';
        }

        return {
          actions: article.actions,
          article: clonedArticle,
          minus: article.quantity,
          plus: article.quantity,
          total: this.formatPrice(article.price),
        };
      });

      this.setTotalPrice(total);

      return rowData;
    },
  },

  methods: {
    edit(Id: string): void {
      this.$emit('edit', Id);
    },

    formatPrice(price): string {
      return MSales.formatedPrice(price);
    },

    minusQuantity(Id: string): void {
      this.$emit('minus', Id);
    },

    plusQuantity(Id: string): void {
      this.$emit('plus', Id);
    },

    remove(Id: string): void {
      this.$emit('remove', Id);
    },

    setTotalPrice(price): void {
      this.totalPrice = MSales.formatedPrice(price);
    },
  },
});
